import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "aphrodite";
import PropTypes from "prop-types";
import { memo, useCallback } from "react";
import { Link } from "react-router-dom";

import sendGAEvent from "utils/sendGAEvent";

import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import getHoverQuery from "styles/getHoverQuery";

const baseStyles = {
  categories: {
    marginBottom: "1rem",
  },
  category: {
    display: "inline-block",
  },
  inlineCategory: {
    marginRight: "0.5em",
    marginBottom: "0.5rem",
  },
  categoryText: {
    ...gStyles.avalonBold,
    display: "inline-block",
    background: "#F8F0FF",

    color: "var(--color-neutral-d4)",
    borderRadius: "4em",
    padding: "0.4em 0.8em 0.3em",
    fontSize: "0.875rem",

    ...getHoverQuery({
      background: "#d6dadd",
      color: colours.bodyText,
    }),
  },
  icon: {
    marginRight: "0.4em",
    height: "11.89px",
  },
};

function BlogCategoryLink(props) {
  const { styles } = useStyles(baseStyles, props);
  const {
    id,
    dataId,
    name,
    link,
    inline,
    icon,
    context,
    articleTitle,
    articleCategory,
  } = props;

  const handleClick = useCallback(
    () =>
      sendGAEvent({
        action: "linkClick",
        context,
        page: "Blog Page",
        articleTitle,
        articleCategory,
      }),
    [context, articleTitle, articleCategory]
  );

  const UseComponent = link ? Link : "div";

  return (
    <li
      data-testid="list"
      className={css(styles.category, inline && styles.inlineCategory)}
      key={id}
      onClick={handleClick}
    >
      <UseComponent
        data-id={dataId || id}
        to={link}
        className={css(styles.categoryText)}
        data-testid="mainComponent"
      >
        {icon && (
          <FontAwesomeIcon
            data-testid="blogIcon"
            icon={icon}
            className={css(styles.icon)}
          />
        )}
        {name}
      </UseComponent>
    </li>
  );
}

BlogCategoryLink.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  dataId: PropTypes.string,
  name: PropTypes.node,
  link: PropTypes.string,
  inline: PropTypes.bool,
  icon: PropTypes.object,
};

BlogCategoryLink.defaultProps = {
  dataId: null,
  name: null,
  link: "",
  inline: false,
  icon: null,
};

export default memo(BlogCategoryLink);
