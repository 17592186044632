import * as paginationConstants from "constants/pagination";

const paginationActions = {
  initList: (props) => ({
    type: paginationConstants.PAGINATION_NEXT_PAGE_REQUEST,
    reloadList: true,
    ...props,
  }),
  replaceList: (props) => ({
    type: paginationConstants.PAGINATION_NEXT_PAGE_REQUEST,
    replaceList: true,
    ...props,
  }),
  setSort: ({ key, sort, direction = "desc", ...rest }) => ({
    type: paginationConstants.PAGINATION_SET_SORT_REQUEST,
    key,
    sort,
    sort_direction: direction,
    ...rest,
  }),
  setGroups: ({ key, groups }) => ({
    type: paginationConstants.PAGINATION_SET_GROUPS_REQUEST,
    key,
    groups,
  }),
  getFilters: (key, entity_type, aggregations) => ({
    type: paginationConstants.LIST_GET_FILTERS_REQUEST,
    key,
    entity_type,
    aggregations,
  }),
  applyFilters: (key, filters, options = {}) => ({
    type: paginationConstants.LIST_APPLY_FILTERS,
    key,
    filters,
    ...options,
  }),
  requestTotalCount: (key, filters) => ({
    type: paginationConstants.PAGINATION_GET_TOTAL_REQUEST,
    key,
    filters,
  }),
  applyConfig: ({ key, ...config }) => ({
    type: paginationConstants.LIST_APPLY_CONFIG,
    key,
    ...config,
  }),
  addFilters: (key, filters) => ({
    type: paginationConstants.LIST_ADD_FILTERS,
    key,
    filters,
  }),
  removeAllFilters: (key) => ({
    type: paginationConstants.LIST_REMOVE_ALL_FILTERS,
    key,
  }),
  removeFilter: (key, filter, dontReloadList = false, options = {}) => ({
    type: paginationConstants.LIST_REMOVE_FILTER,
    key,
    filterKey: filter,
    dontReloadList,
    ...options,
  }),
  setSearchTerm: (key, term, onSearchTermRequest, dontLoadFilters) => ({
    type: paginationConstants.LIST_SET_SEARCH_TERM,
    key,
    term,
    onSearchTermRequest,
    dontLoadFilters,
  }),
  setListNotLoaded: (key) => ({
    type: paginationConstants.LIST_UPDATE,
    key,
    update: { loaded: false },
  }),
  rawInitList: (props) => ({
    type: paginationConstants.PAGINATION_INIT_LIST_CREATE,
    ...props,
  }),
  updateList: (key, update, deep = false) => ({
    type: paginationConstants.LIST_UPDATE,
    key,
    update,
    deep,
  }),
  removeListSortIds: (key, sort, ids) => ({
    type: paginationConstants.REMOVE_LIST_SORT_IDS,
    key,
    ids,
    sort,
  }),
  removeIdsFromList: (key, ids) => ({
    type: paginationConstants.REMOVE_ID_FROM_LIST,
    key,
    ids,
  }),
  nextPage: (key, props) => ({
    type: paginationConstants.PAGINATION_NEXT_PAGE_REQUEST,
    key,
    ...props,
  }),
  nextPageSuccess: (key, response, props) => ({
    type: paginationConstants.PAGINATION_NEXT_PAGE_SUCCESS,
    key,
    response,
    ...props,
  }),
  loadCategoriesList: (key) => ({
    type: paginationConstants.LOAD_CATEGORIES_LIST,
    key,
  }),
  loadCreatorsList: (key) => ({
    type: paginationConstants.LOAD_CREATORS_LIST,
    key,
  }),
  loadCreditsList: (key) => ({
    type: paginationConstants.LOAD_CREDITS_LIST,
    key,
  }),
  loadContactsList: (key) => ({
    type: paginationConstants.LOAD_CONTACTS_LIST,
    key,
  }),
  loadEpisodesList: (key) => ({
    type: paginationConstants.LOAD_EPISODES_LIST,
    key,
  }),
  loadIntegrationsList: (key) => ({
    type: paginationConstants.LOAD_INTEGRATIONS_LIST,
    key,
  }),
  loadModerationTasksList: (key) => ({
    type: paginationConstants.LOAD_MODERATION_TASKS_LIST,
    key,
  }),
  loadPodcastsList: (key) => ({
    type: paginationConstants.LOAD_PODCASTS_LIST,
    key,
  }),
  loadPostsList: (key) => ({
    type: paginationConstants.LOAD_POSTS_LIST,
    key,
  }),
  loadReviewsList: (key) => ({
    type: paginationConstants.LOAD_REVIEWS_LIST,
    key,
  }),
  loadSuggestedCreditsList: (key) => ({
    type: paginationConstants.LOAD_SUGGESTED_CREDITS_LIST,
    key,
  }),
  loadUserlistsList: (key) => ({
    type: paginationConstants.LOAD_USER_LISTS_LIST,
    key,
  }),
  loadUserRequestsList: (key) => ({
    type: paginationConstants.LOAD_USER_REQUESTS_LIST,
    key,
  }),
  loadUsersList: (key) => ({
    type: paginationConstants.LOAD_USERS_LIST,
    key,
  }),
  disableListAutoApplyUrlFilter: (key) => ({
    type: paginationConstants.LIST_DISABLE_AUTO_APPLY_FILTER_FROM_URL,
    key,
  }),
};

export default paginationActions;
