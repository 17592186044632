import { css } from "aphrodite";
import { List } from "immutable";
import PropTypes from "prop-types";
import { memo } from "react";

import BlogCategoryLink from "./BlogCategoryLink";

import { generateBlogCategoryPath } from "utils/blog";

import { useStyles } from "hooks/useStyles";

const baseStyles = {
  categories: {
    marginBottom: "1.5rem",
  },
};

function BlogCategoryList(props) {
  const { styles } = useStyles(baseStyles, props);
  const {
    categories,
    prependItems,
    appendItems,
    inline,
    articleTitle,
    articleCategory,
  } = props;

  return (
    <ul className={css(styles.categories)} data-testid="blogListUL">
      {prependItems}
      {categories.map((category) => (
        <BlogCategoryLink
          dataId="blog-post-category"
          key={category.get("id")}
          link={generateBlogCategoryPath(category.get("slug"))}
          id={category.get("id")}
          name={category.get("name")}
          inline={inline}
          context="BlogCategoryLink"
          articleTitle={articleTitle}
          articleCategory={articleCategory}
        />
      ))}
      {appendItems}
    </ul>
  );
}

BlogCategoryList.propTypes = {
  categories: PropTypes.instanceOf(List).isRequired,
  prependItems: PropTypes.node,
  appendItems: PropTypes.node,
  inline: PropTypes.bool,
};

BlogCategoryList.defaultProps = {
  prependItems: null,
  appendItems: null,
  inline: false,
};

export default memo(BlogCategoryList);
